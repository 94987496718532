import React, { useCallback, useRef, useState, MutableRefObject, useEffect } from "react";
import Input from "../components/Input"
import { ReactTabulator } from "react-tabulator";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { minMaxFilterFunction, minMaxFilterEditor } from "./HeaderFilterNumber";
import { dateEditor } from "./DateEditor"

interface TableProps {
  data?: any[]
  columns?: any[]
  layout?: string
  tableRef?: MutableRefObject<HTMLInputElement | null>
  submittedValue?: string
}

const getDataByKey = (key: string) => {
  return JSON.parse(localStorage.getItem(key) || "[]")
}

const storeDataByKey = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const COLUMNS_KEY = 'table-columns';
const ROWS_KEY = 'table-rows';
const DATA_TITLE_KEY = 'data-title';

const EditableTable: React.FC<TableProps> = () => {

  let tableRef = useRef<any>(null)
  const [inputValue, setInputValue] = useState("");
  const [columnName, setColumnName] = useState("");
  const [columnType, setColumnType] = useState("plaintext");
  const [customColumns, setCustomColumns] = useState<{
    title: string
    field: string
    formatter: any
    formatterParams?: any
    editor: any
    headerFilter: any
    headerFilterFunc?: any
    headerFilterPlaceholder?: any
    sorter?: string
    headerSort: boolean
    editable?: boolean
  }[]>([])

  const [customRows, setCustomRows] = useState<{}[]>([])

  const [tableKey, setTableKey] = React.useState(`${Math.random()}`)

  useEffect(() => {
    const columns = getDataByKey(COLUMNS_KEY);
    setCustomColumns(columns)
    setTableKey(`${Math.random()}`)
  }, [])

  useEffect(() => {
    const rows = getDataByKey(ROWS_KEY);
    setCustomRows(rows)
    setTableKey(`${Math.random()}`)
  }, [])

  useEffect(() => {
    const dataTitle = getDataByKey(DATA_TITLE_KEY);
    setInputValue(dataTitle)
  }, [])

  const handleChange = (str: string) => {
    setInputValue(str)
  }

  const newColumn = useCallback(() => {
    const column = {
      title: columnName,
      field: columnName,
      formatter: columnType,
      editor: true,
      headerFilter: true,
      headerFilterPlaceholder: "Filter column",
      width: 150,
      headerSort: true,
    }

    if (columnName !== "") {
      setCustomColumns([...customColumns, column])
    }

    setTableKey(`${Math.random()}`)

    setColumnName("")

  }, [customColumns, setCustomColumns, columnType, columnName, setColumnName])

  const data = customColumns.map((columnField) => columnField.field);

  if (data.includes(columnName)) {
    alert("This column already exists. Please select a new column name")
    setColumnName("")
  }

  const newRow = useCallback(() => {
    const newRow = {}
    setCustomRows([...customRows, newRow])
    setTableKey(`${Math.random()}`)
  }, [customRows, setCustomRows])

  useEffect(() => {
    storeDataByKey(COLUMNS_KEY, customColumns)
    customColumns.map((column: any) => {
      if (column.formatter === "money") {
        column.headerFilter = minMaxFilterEditor
        column.headerFilterPlaceholder = "Filter column"
        column.sorter = "number"
        column.headerFilterFunc = minMaxFilterFunction
        column.editor = "number"
        column.formatterParams = {
          decimal: ",",
          thousand: ".",
          symbol: "£",
          symbolAfter: "p",
          precision: false,
        }
      }

      if (column.formatter === "datetime") {
        column.sorter = "date"
        column.editor = dateEditor
        column.formatterParams = {
          inputFormat: "yyyy-MM-dd HH:ss",
          outputFormat: "DD/MM/YYYY hh:mm:ss",
        }
      }

      if (column.formatter === "progress") {
        column.headerFilter = false
        column.sorter = "number"
        column.editor = "number"
        column.formatterParams = {
          min: 0,
          max: 10,
          color: ["green", "orange", "red"],
          legendColor: "#000000",
          legendAlign: "center",
        }
        column.editable = true
      }
    })
  }, [customColumns])

  useEffect(() => {
    storeDataByKey(DATA_TITLE_KEY, inputValue)
  }, [inputValue])

  const onDatachanged = () => {
    storeDataByKey(ROWS_KEY, customRows)
  };

  return (
    <>
      <form >
        <label>
          <Input
            onChange={handleChange}
            name="name"
            value={inputValue}
            placeholder="Add title"
          />
        </label>
      </form>
      <div>
        <label >Column Name: </label>
        <input type="text" id="column" value={columnName} onChange={(e) => setColumnName(e.target.value)} />
        <select onChange={(e) => setColumnType(e.target.value)}>
          <option value="plaintext">Text</option>
          <option value="money">Money</option>
          <option value="star">Rating</option>
          <option value="progress">Progress Bar</option>
          <option value="datetime">Date or Time</option>
          <option value="star">Star</option>
          <option value="tickCross">TickCross</option>

        </select>
        <button type="button" id="add-column-button" onClick={newColumn}>Add column</button>
        <button type="button" id="add-row-button" onClick={newRow}>Add row</button>
      </div>
      <div>
        <ReactTabulator
          key={tableKey}
          tableRef={tableRef}
          data={customRows}
          columns={customColumns as any}
          layout={"fitData"}
          className="table"
          height="311px"
          options={
            {
              persistenceMode: "local",
              persistence: {
                sort: true,
                filter: true,
                group: {
                  groupBy: true,
                  groupStartOpen: false,
                  groupHeader: false,
                },
                page: true,
                columns: true,
              },
              persistenceID: "production-data",
              persistenceWriterFunc: (id: any, type: any, data: any) => {
                localStorage.setItem(id + "-" + type, JSON.stringify(data));
              },
              persistenceReaderFunc: (id: any, type: any) => {
                var data = localStorage.getItem(id + "-" + type);
                return data ? JSON.parse(data) : false;
              }
            }
          }
          events={{
            dataChanged: onDatachanged
          }}
        />
      </div>
    </>
  )
}

export default EditableTable
