import { DateTime } from "luxon";

window.DateTime = DateTime

export var dateEditor = function (cell, onRendered, success, cancel) {
  // var DateTime = luxon.DateTime
  //cell - the cell component for the editable cell
  //onRendered - function to call when the editor has been rendered
  //success - function to call to pass the successfuly updated value to Tabulator
  //cancel - function to call to abort the edit and return to a normal cell

  var cellValue = cell.getValue() || '';

  const input = document.createElement("input");

  input.setAttribute("type", "date");

  input.style.padding = "4px";
  input.style.width = "100%";
  input.style.boxSizing = "border-box";

  input.value = cellValue;

  onRendered(function () {
    input.focus();
    input.style.height = "100%";
  });

  function onChange() {
    if (input.value !== cellValue) {
      // const formatted = DateTime.fromFormat(input.value, "yyyy-MM-dd").toFormat("dd/MM/yyyy")
      // success(DateTime.fromFormat(input.value, "yyyy-MM-dd").toFormat("dd/MM/yyyy"));
      success(input.value);
    }
  }

  //submit new value on blur or change
  input.addEventListener("blur", onChange);

  //submit new value on enter
  input.addEventListener("keydown", function (e) {
    if (e.keyCode === 13) {
      onChange();
    }

    if (e.keyCode === 27) {
      cancel();
    }
  });

  return input;
};