import React from 'react';
import './App.css';
import EditableTable from './components/EditableTable';
import CreateTableButton from './components/CreateTableButton';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/editableTable">
          <EditableTable /> 
        </Route>
        <Route path="/">
          <CreateTableButton  />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
