import React from "react";
import { Link } from "react-router-dom";

const CreateTableButton = () => {
    return (
        <Link to={"/editableTable"}>
            <button>Create table</button>
        </Link>
    )
}

export default CreateTableButton