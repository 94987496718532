import React from "react"

type InputProps = {
    onChange: (str: string) => void;
    name: string;
    value?: string;
    placeholder: string;
}

const Input = ({onChange, name, value = "", placeholder}: InputProps) => { 
    return (
        <input 
            type="text" 
            name={name}
            value={value} 
            onChange={event => onChange(event.target.value)}
            placeholder={placeholder}
        />
    )
}

export default Input